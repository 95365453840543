import React from "react";
import styled from "styled-components";
import Lottie from "lottie-react";

export default function BlogBox({ tag, title, text, action, author, anim }) {
  return (
    <WrapperBtn className="animate pointer" onClick={action ? () => action() : null}>
      <Wrapper className="whiteBg radius8 shadow" style={{fontFamily: "Futura PT"}}>
      <center>

        <h3 className="font20 extraBold">{title}</h3>
        
        {(anim) ? <Lottie animationData={anim} style={{zIndex: 9, height: '200px', margin: '30px'}}></Lottie> : null}

        <a style={{ padding: "30px 0", lineHeight: "20px", fontSize: "12pt" }}>
          {text}
        </a>
      </center>

        <p className="font13 extraBold">{author}</p>
        <div className="flex">
          <p className="tag coralBg radius6 font13 extraBold">{tag}</p>
        </div>
      </Wrapper>
    </WrapperBtn>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 30px;
`;
const WrapperBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  :hover {
    opacity: 0.5;
  }
`;
