import React from "react";
import styled from "styled-components";
import Lottie from "lottie-react";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage1 from "../../assets/images/zolno.jpeg";
import AddImage2 from  "../../assets/images/jpeg.jpeg";
import ServicesAnimation from "../../assets/animations/anim-client-explain.json";

export default function Services() {
  return (
    <Wrapper id="services">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Le principe de notre calculateur</h1>
            <p className="font13">
              <br />
              Comment faisons-nous ? 
              Pour réaliser un calcul précis et personnalisé, nous suivons le processus suivant, grâce à notre savoir-faire et à notre technologie avancée.
             
            </p>
            <Lottie
                    animationData={ServicesAnimation}
                    style={{zIndex: 9, height: '700px'}}
                    // interactivity={interactivity}
          />
          <center>
           <p className="font13">
           La qualité des informations est essentielle pour réaliser une étude complète et sur mesure. Elle permet d'éviter le surdimensionnement fréquent des installations et garantit une solution parfaitement adaptée à vos besoins.
            </p>
            </center>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="gps"
                title="Analyse précise de votre toiture grâce à l’imagerie satellite"
                subtitle="
Avec votre adresse exacte ou vos coordonnées GPS, nous réalisons une étude approfondie de votre toiture en utilisant les données d'imagerie satellite mondiale de Google et des outils informatiques avancés. Nous mesurons des éléments essentiels comme l'ombrage lointain, la pente idéale du toit, son angle et son orientation avec une grande précision. Ces informations sont cruciales pour optimiser la simulation photovoltaïque et assurer une installation parfaitement adaptée à vos besoins."
              />


            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="pvwatt"
                title="Simulation détaillée de la production solaire pour votre domicile"
                subtitle="Grâce à notre logiciel de simulation, basé sur les données du National Renewable Energy Laboratory, nous réalisons une analyse détaillée de la production solaire heure par heure pour votre domicile. Nous simulons plusieurs tailles d'installations photovoltaïques en tenant compte de tous les paramètres spécifiques de votre toiture et de l'espace disponible."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="power"
                title="Relevé de votre consommation horaire grâce au compteur Linky"
                subtitle="Aujourd'hui, chaque foyer est équipé d'un compteur Linky. Grâce à cet appareil, nous pouvons suivre votre consommation électrique heure par heure via une liaison avec votre compte Enedis. Notre logiciel utilise ces données pour aligner précisément votre consommation avec la production d'énergie photovoltaïque, permettant ainsi de personnaliser votre installation et maximiser votre retour sur investissement."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox icon="offer" title="Génération d'une offre personnalisée et sur mesure pour une installation photovoltaïque optimale" subtitle="Après avoir conçu l'installation photovoltaïque parfaitement adaptée à vos besoins, nous vous fournissons un devis personnalisé. Parmi la multitude d'installateurs, nous avons sélectionné les meilleurs partenaires. Ils offrent des panneaux fabriqués en France à des prix compétitifs, avec une garantie pièces et main d'œuvre de 25 ans. Rien que ça !" />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h4 className="font15 semiBold">Quelques mots sur nous</h4>
                <h2 className="font40 extraBold">"Stay hungry, Stay foolish"</h2>
                <br />
                <p className="font12">
                Nous sommes une équipe dynamique, pleine de talents unis par un objectif commun : participer au changement. Notre mission est de réconcilier économie et écologie, et la bonne nouvelle, c'est que c'est possible grâce au solaire photovoltaïque.
                Pour réaliser ce projet, nous avons réuni des ingénieurs en informatique et en énergies renouvelables. Avec Mutualix, nous contribuons activement à cette transformation.
                Rejoignez-nous et passez à l'action !
                </p>
                <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0"}}>
                  {/* <div style={{ width: "190px" }}>
                    <FullButton title="Rejoins nous !" action={() => alert("clicked")} />
                  </div> */}
                  <div style={{ width: "190px", marginLeft: "15px" }}>
                    <FullButton title="Plus d'infos" action={() => alert("clicked")} border />
                  </div>
                </ButtonsRow>
              </AddLeft>
        

           
              <AddRight>
                <AddRightInner>
                <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={AddImage1} alt="office" />

                    </AddImgWrapp1>
                    <p>
                          <h1>
                          Stéphane ZOLNOWSKI
                          </h1>
                          CEO Fondateur
                          <br></br>
                          <br></br>
                          <h6>
                          Ingénieur en énergies renouveleables, anciennement ingénieur conseils d'une entreprise leader du secteur de la technologie du bâtiment
                          </h6>
                      </p>
                    </div>
                  </AddRightInner>
                  <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp2>
                      <img src={AddImage2} alt="office" />
                    </AddImgWrapp2>
                    <p>
                          <h1>
                          Jérémy PEGUET
                          </h1>
                          CTO Fondateur
                          <br></br>
                          <br></br>
                          <h6>
                          Architecte en technologie numérique, anciennement directeur technique d'une école d'informatique supérieure de renomée mondiale
                          </h6>
                      </p>
                    </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: 50px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 30%;
  margin: 70px 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 5rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 60px 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 5rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;