import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Components
import TestimonialBox from "../Elements/TestimonialBox";

export default function TestimonialSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Leur service de calcul automatisé est super rapide. J'ai obtenu une analyse complète en un rien de temps. Professionnels et réactifs, ils m'ont convaincu de passer à l'énergie solaire. Franchement, c'est top !"
            author="Tristan Baptista"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Très pro et efficace. Leur outil de calcul m'a donné une solution photovoltaïque parfaite pour mes besoins. Résultat : des économies sur ma facture d'électricité. Je recommande sans hésiter."
            author="Antoine Le Magueresse"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Service à un coût très compétitif. J'ai eu une estimation rapide et précise qui m'a aidé à décider rapidement. Maintenant, je fais des économies et je fais du bien à la planète. Super service !"
            author="Quentin Bollache"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Leur outil de calcul est rapide et fiable. En un rien de temps, j'ai pu voir combien je pouvais économiser avec les panneaux solaires. Simple, rapide, et efficace. Rien à redire."
            author="Jerome Curdy"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Super rapport qualité/prix. Devis précis et recommandations pertinentes. Je paie moins pour mon électricité et je contribue à l'écologie. Vraiment satisfait de ce service."
            author="Francoise Marchand"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Calculateur automatisé au top. Rapide et précis, les solutions proposées sont vraiment de qualité. Les économies sont réelles, et en plus, c'est bon pour la planète. A recommander !"
            author="Cindy Curdy"
          />
        </LogoWrapper>
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
