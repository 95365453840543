import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";
import FullButton from "../Buttons/FullButton";
import TestimonialSlider from "../Elements/TestimonialSlider";

import AnimTaxes from "../../assets/animations/anim-taxes.json";
import AnimMoney from "../../assets/animations/anim-money.json";
import AnimTime from "../../assets/animations/anim-lifespan.json";
import AnimRecycle from "../../assets/animations/anim-recycle.json";
import AnimCO2 from "../../assets/animations/anim-carbon-foot.json";
import AnimPrice from "../../assets/animations/anim-cart.json";

export default function Blog() {
  return (
    <Wrapper id="blog">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Infos utiles sur le photovoltaïque</h1>
            <p className="font13">
              <br />
              Quand les discussions touchent au photovoltaïque, on entend tout et son contraire. Nous vous proposons ici des réponses fiables aux questions qui nous sont le plus souvent posées. 
              <br />
              Les infos ci-dessous sont tenues à jour.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Les subventions pour le photovoltaïque"
                text="Il existe deux subventions pour le photovoltaïque.

La première est une prime liée à l'installation et à sa puissance. Actuellement, le barème est de 260 €/kWc pour une installation de 3 kWc ou moins, et de 190 €/kWc pour une installation allant jusqu'à 9 kWc.

La deuxième subvention est une obligation d'achat sur 20 ans. L'énergie produite sans être consommée est revendue à 13 cts/kWh, soit environ la moitié du prix de l'énergie achetée. Cela permet de réaliser des économies significatives, mais il est important de ne pas surproduire pour maximiser ces économies."
                anim={AnimMoney}
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="La durée de vie d'une installation photovoltaïque est de plus de 25 ans !"
                text="Les panneaux proposés par Mutualix et ses partenaires sont garantis 25 ans, pièces et main d'œuvre incluses. Vous pouvez dormir sur vos deux oreilles grâce à la fiabilité de notre matériel. La durée de vie de l'installation dépasse largement la garantie : certaines installations fonctionnent depuis plus de 30 ans, comme la centrale de Phébus 1, pionnière dans le domaine, qui fête ses 32 ans. Le plus grand risque serait une tempête ou un épisode de grêle exceptionnel, mais pas de panique : votre installation est couverte par l'assurance habitation."
                anim={AnimTime}
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Les panneaux solaires sont recyclables à 95%"
                text="Avec le boom des installations photovoltaïques, le recyclage accusait un certain retard. Aujourd'hui, ce retard est comblé grâce à de nombreuses structures déployées à travers le pays. L'État a investi massivement pour rendre ces projets viables. Désormais, le verre, le cadre en aluminium et de nombreux autres composants sont recyclés. Même la collecte des panneaux est prise en charge. Le taux de recyclabilité a maintenant atteint 95 %."
                anim={AnimRecycle}
                // tag="company"
                // tag="company"
                // author="Luke Skywalker, 2 days ago"
                action={() => alert("clicked")}
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Bilan carbonne des panneaux solaires"
                text="Qu'entend-on par bilan carbone ?
Comme tout processus industriel, la fabrication, le transport et l'installation des panneaux solaires nécessitent de l'énergie. Pour produire de l'électricité, une partie de cette énergie est carbonée. Selon l’ADEME, l'Agence de la transition écologique, il faut en moyenne 3 ans pour qu'un panneau solaire atteigne la neutralité carbone. En d'autres termes, en 3 ans, le panneau a économisé autant de CO2 qu'il en a émis lors de sa production. Étant donné qu'un panneau solaire est fonctionnel pendant environ 30 ans, son impact carbone est largement positif.
"
                // tag="company"
                // author="Luke Skywalker, 2 days ago"
                anim={AnimCO2}
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Fiscalité du photovoltaïque"
                text="Les Avantages Fiscaux du Photovoltaïque
Le photovoltaïque offre plusieurs avantages fiscaux. Pour les petites installations de moins de 3 kWc, la TVA est réduite à 10 % et les revenus issus de la revente d'électricité sont défiscalisés. Pour les installations de plus de 3 kWc, la TVA est de 20 %, mais il y a un abattement fiscal de 71 % sur les revenus de la revente. Il ne vous reste donc que 29 % de la somme à déclarer selon le taux d'imposition de votre tranche finale.
                "
                // anim = FiscalityAnim
                // tag="economy"
                // author="Luke Skywalker, 2 days ago"
                anim={AnimTaxes}
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Protégez-vous contre l'inflation de l'électricité grâce au solaire photovoltaïque"
                text="Bien que réglementé, le prix de l'électricité a explosé ces dernières années. En 15 ans, le tarif a été multiplié par 2,5, passant de 10 cts/kWh à 25 cts/kWh aujourd'hui, comme le montre ce graphique (https://www.fournisseurs-electricite.com/contrat-electricite/prix/evolution). Les prix continuent de croître et devraient dépasser 35 cts/kWh avant 2030. Protégez-vous contre cette inflation avec le solaire photovoltaïque : plus les prix montent, plus votre installation devient rentable."
                // tag="company" https://www.fournisseurs-electricite.com/contrat-electricite/prix/evolution
                // author="Luke Skywalker, 2 days ago"
                anim={AnimPrice}
                action={() => alert("clicked")}
              />
            </div>
          </div>
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              {/* <FullButton title="Load More" action={() => alert("clicked")} /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg" style={{padding: '50px 0'}}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Ils l'ont fait, pourquoi pas vous ?</h1>
            <p className="font13">
              <br />
              Qui de mieux que nos clients pour parler de nous !
              <br />

              Chez Mutualix, nous croyons que nos clients sont nos meilleurs ambassadeurs. Leur satisfaction et leurs témoignages parlent d'eux-mêmes. Découvrez pourquoi ils nous font confiance pour leurs installations photovoltaïques et comment nos solutions ont transformé leur quotidien.
            </p>
          </HeaderInfo>
          <TestimonialSlider />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;