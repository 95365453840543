import React from "react";
import styled from "styled-components";

import Lottie from "lottie-react";
// Assets
import PVWattAnim from "../../assets/animations/anim-pvwatts.json";
import GPSAnim from "../../assets/animations/anim-local.json";
import PowerAnim from "../../assets/animations/anim-charts.json";
import OfferAnim from "../../assets/animations/anim-house-contract.json";

export default function ServiceBox({icon, title, subtitle}) {
  let getIcon;

  switch (icon) {
    case "gps":
      getIcon = GPSAnim;
      break;
    case "pvwatt":
      getIcon = PVWattAnim;
      break;
    case "power":
      getIcon = PowerAnim;
      break;
    case "offer":
      getIcon = OfferAnim;
      break;
    default:
      getIcon = GPSAnim;
      break;
  }


  return (
    <Wrapper className="flex flexColumn">

      {/* <IconStyle>{getIcon}</IconStyle> */}
        <Lottie
                    animationData={getIcon}
                    style={{zIndex: 9, height: '200px'}}
                    // interactivity={interactivity}
        />
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const IconStyle = styled.div`
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 40px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;