import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Assets
import ClientLogo01 from "../../assets/images/logo_nrel_c.jpg";
import ClientLogo02 from "../../assets/images/logo_enedis_header.webp";
// import ClientLogo03 from "../../assets/images/googlemaps.png";
import ClientLogo04 from "../../assets/images/soren-logo.svg";
import ClientLogo05 from "../../assets/images/dualsunlogonew.svg";
import ClientLogo06 from "../../assets/images/logo-ademe.svg";

export default function ClientSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper>
          <ImgStyle src={ClientLogo01} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper>
          <ImgStyle src={ClientLogo02} alt="client logo" />
        </LogoWrapper>
        {/* <LogoWrapper>
          <ImgStyle src={ClientLogo03} alt="client logo" />
        </LogoWrapper> */}
        <LogoWrapper>
          <ImgStyle src={ClientLogo04} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper>
          <ImgStyle src={ClientLogo05} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper>
          <ImgStyle src={ClientLogo06} alt="client logo" />
        </LogoWrapper>
        {/* <LogoWrapper>
          <ImgStyle src={ClientLogo03} alt="client logo" />
        </LogoWrapper> */}
        <LogoWrapper>
          <ImgStyle src={ClientLogo04} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper>
          <ImgStyle src={ClientLogo01} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper>
          <ImgStyle src={ClientLogo02} alt="client logo" />
        </LogoWrapper>
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;  // Center content vertically
  justify-content: center; // Center content horizontally
  // width: 100%;
  // height: 100px;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0;
  }
`;

const ImgStyle = styled.img`
  width: 90%;
  height: auto; // Ensure the aspect ratio of the image is maintained
  padding: 5%;
  box-sizing: border-box; // Include padding in the element's total width and height
`;
