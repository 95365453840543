import React from "react";
import styled from "styled-components";
import Lottie from "lottie-react";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import HeaderImage from "../../assets/images/vertical-logo.png";
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";
import HeaderAnimation from "../../assets/images/anim1.json";


export default function Header() {
  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <div>
          <h1 className="extraBold font60">Votre bouclier solaire.</h1>
          <HeaderP className="font13 semiBold">
          L'énergie solaire photovoltaïque, c'est rentable ?
Absolument ! Mais cela dépend de la condition suivante : l'installation doit être dimensionnée en fonction de vos consommations réelles. C'est pourquoi Mutualix lance le premier calculateur de rentabilité photovoltaïque basé sur vos consommations heure par heure.

En utilisant nos services, vous pouvez installer un véritable bouclier solaire pour vous protéger contre l'inflation des prix de l'électricité. Comment ça fonctionne ? C'est simple et gratuit. Laissez-vous guider !

          </HeaderP>
          {/* <BtnWrapper>
            <FullButton title="En savoir plus" />
          </BtnWrapper> */}
        </div>
      </LeftSide>
      <RightSide>
        <ImageWrapper>
          {/* <Img className="radius8" src={HeaderImage} alt="office" style={{zIndex: 9}} /> */}
          <Lottie
                    animationData={HeaderAnimation}
                    style={{zIndex: 9, height: '800px'}}
                    // interactivity={interactivity}
          />
          <QuoteWrapper className="flexCenter darkBg radius8">
            <QuotesWrapper>
              <QuotesIcon />
            </QuotesWrapper>
            <div>
              <p className="font11 whiteColor">
                <em> "
                
Grâce à Mutualix, j'ai eu une installation parfaitement adaptée à mes besoins et plus économique. Le processus a été simple, rapide et efficace, surtout comparé à d'autres qui tendent à surdimensionner les systèmes. Merci !" 

                </em>
              </p>
              <p className="font13 orangeColor textRight" style={{marginTop: '10px'}}>Simon Chamouillet 

              </p>
            </div>
          </QuoteWrapper>
          <DotsWrapper>
            <Dots />
          </DotsWrapper>
        </ImageWrapper>
        
      </RightSide>
    </Wrapper>
  );
}


const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;


